<template>
  <div>
    <div class="card-toolbar mb-5">
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>
    <!--begin::supplier-->
    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <!-- Filter -->
        <div class="m-form m-form--fit m--margin-bottom-20" v-if="showAdvancedSearch">
          <div class="row pb-5">
            <div class="col-lg-6 mt-2 mb-2">
              <label>{{ $t('payment_purchase_invoices.type') }}</label>

              <select name="" id="type_type" v-model="filters.type_type" class="custom-select">
                <option value="PurchaseInvoice">{{ $t('payment_purchase_invoices.purchase_invoice') }}</option>
                <option value="PurchaseRefund">{{ $t('payment_purchase_invoices.purchase_refund') }}</option>
              </select>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="invoice_code">{{ $t('payment_purchase_invoices.invoice_code') }}</label>
              <input v-model="filters.invoice_code" type="text" id="invoice_code" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('payment_purchase_invoices.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('payment_purchase_invoices.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_amount">{{ $t('payment_purchase_invoices.from_amount') }}</label>
              <input v-model="filters.from_amount" type="number" id="from_amount" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_amount">{{ $t('payment_purchase_invoices.to_amount') }}</label>
              <input v-model="filters.to_amount" type="number" id="to_amount" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="supplier_id">{{ $t('payment_purchase_invoices.supplier') }}</label>

              <multiselect
                  v-model="supplier"
                  :placeholder="$t('payment_purchase_invoices.supplier')"
                  label="name"
                  track-by="id"
                  :options="suppliers"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getSuppliers($event)">
              </multiselect>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('payment_purchase_invoices.collected_by') }}</label>
              <multiselect
                  v-model="collected_by"
                  :placeholder="$t('payment_purchase_invoices.collected_by')"
                  label="name"
                  track-by="id"
                  :options="users"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>

            <div class="col-lg-6  mt-2 mb-2">
              <label>{{ $t('payment_purchase_invoices.payment_method') }}</label>
              <select name="" id="payment_method" v-model="filters.payment_method" class="custom-select">
                <option v-for="row in payment_methods" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
            </div>
            <div class="col-lg-6 mt-2 mb-2">
              <label>{{ $t('payment_purchase_invoices.payment_status') }}</label>
              <select name="" id="payment_status" v-model="filters.payment_status" class="custom-select">
                <option v-for="row in payment_status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
              </select>
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('payment_purchase_invoices.invoiced_by') }}</label>

              <multiselect
                  v-model="invoiced_by"
                  :placeholder="$t('payment_purchase_invoices.invoiced_by')"
                  label="name"
                  track-by="id"
                  :options="users"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getUsers($event)">
              </multiselect>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{ $t('search') }}</span>
                              </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{ $t('reset_search') }}</span>
                              </span>
              </button>
            </div>
          </div>
        </div>
        <!-- End Filter -->
      </div>
    </div>
    <div class="card card-custom">
      <div class="card-body">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
          <template slot="supplier_info" slot-scope="props">
            <div>
              <div>
                <span>{{ $t('payment_purchase_invoices.supplier_code') }}: </span>
                <span>{{ props.row.supplier_code }}</span>
              </div>
              <div>
                <span>{{ $t('payment_purchase_invoices.supplier_name') }}: </span>
                <span>{{ props.row.supplier_fullname }}</span>
              </div>
              <div><span>{{ $t('payment_purchase_invoices.collected_by') }}: </span>
                <span>{{ props.row.collected_user_name }}</span>
              </div>
              <div>
                <span>{{ $t('payment_purchase_invoices.type') }}: </span>
                <span v-if="props.row.type_type_label == 'PurchaseInvoice'">{{ $t('payment_purchase_invoices.purchase_invoice') }}</span>
                <span v-else-if="props.row.type_type_label == 'PurchaseRefund'">{{ $t('payment_purchase_invoices.purchase_refund') }}</span>
              </div>
            </div>
          </template>
          <template slot="payment_details" slot-scope="props">
            <div v-if="props.row.code">
              <span>{{ $t('payment_purchase_invoices.code') }}: </span><span>{{ props.row.code }}</span>
            </div>
            <div>
              <span>{{ props.row.amount }} {{ props.row.currency_code }}</span>
            </div>
<!--            <div>-->
<!--              <span>({{ props.row.payment_status_name }})</span>-->
<!--            </div>-->
            <div>
              <span>{{ $t('payment_purchase_invoices.payment_method') }}: </span><span>{{ props.row.payment_method_name }}</span>
            </div>
            <div><span>{{ $t('payment_purchase_invoices.payment_date') }}: </span><span>{{ props.row.payment_date }}</span></div>
            <div v-if="props.row.attachment_url">
              <a :href="props.row.attachment_url" target="_blank">{{ $t('payment_purchase_invoices.attachment') }}</a>
            </div>
          </template>
          <template slot="status" slot-scope="props">
            <button v-if="$can('payment_purchase_invoices.change_status')" @click="changeStatus(props.row.id, props.row.payment_status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.payment_status_name }})
            </button>
            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ $t('change_status') }} ({{ props.row.payment_status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">
            <!--             -->
            <v-btn icon v-b-tooltip.hover :title="$t('edit')" v-if="$can('payment_purchase_invoices.update') && props.row.payment_status != 2" color="pink" :to="`/purchases/payment_purchase_invoices/edit/${props.row.id}/${props.row.url_type}`">
              <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
            </v-btn>
            <!--              -->
            <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('payment_purchase_invoices.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
            <div>
              <b-dropdown right :text="$t('more_actions')" variant="outline-primary btn-sm" class="m-2">
                <router-link v-if="$can('payment_purchase_invoices.details')" class="dropdown-item" :to="`/purchases/payment_purchase_invoices/payment_purchase_details/${props.row.id}`">
                  {{ $t('payment_purchase_details.payment_details') }}
                </router-link>
                <b-dropdown-item v-if="$can('payment_purchase_invoices.details')" @click="showDetails(props.row.id)">{{ $t('payment_purchase_invoices.details') }}</b-dropdown-item>
                <router-link v-if="$can('payment_purchase_invoices.send_mail')" class="dropdown-item" :to="`/settings/custom/email/${props.row.id}/PaymentSales`">
                  {{ $t('send_mail') }}
                </router-link>
              </b-dropdown>
            </div>
          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::supplier-->
    <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="payment_status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          :show-confirm-message="true"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

export default {
  name: "index-payment_purchase_invoices",
  components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'sales/payment_sales_invoices',
      routeChangeStatus: 'sales/payment_sales_invoice/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        invoice_code: null,
        from_date: null,
        to_date: null,
        from_amount: null,
        to_amount: null,
        supplier_id: null,
        collected_user_id: null,
        payment_method: null,
        payment_status: null,
        invoiced_by_id: null,
        type_type: null,
        filter_type: 'purchase',
      },
      columns: ['#','supplier_info', 'payment_details', 'status', 'actions'],
      data: [],
      payment_methods: [],
      payment_status_list: [],
      users: [],
      supplier: null,
      suppliers: [],
      collected_by: null,
      invoiced_by: null,
      innerId: null,
      statusId: null,

    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          supplier_info: that.$t('payment_purchase_invoices.supplier_info'),
          payment_details: that.$t('payment_purchase_invoices.payment_details'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
    collected_by: function (val) {
      if (val) {
        this.filters.collected_user_id = val.id;
      } else {
        this.filters.collected_user_id = null;
      }
    },
    invoiced_by: function (val) {
      if (val) {
        this.filters.invoiced_by_id = val.id;
      } else {
        this.filters.invoiced_by_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.payment_purchase_invoices")}]);
    this.getPaymentMethods();
    this.getPaymentStatus();
    // this.getUsers();
  },
  methods: {

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.invoice_code = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.from_amount = null;
      this.filters.to_amount = null;
      this.filters.supplier_id = null;
      this.filters.collected_user_id = null;
      this.filters.payment_method = null;
      this.filters.payment_status = null;
      this.filters.invoiced_by_id = null;
      this.filters.type_type = null;
      this.supplier = null;
      this.collected_by = null;
      this.invoiced_by = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getPaymentStatus() {
      ApiService.get(this.mainRouteDependency + "/payment_status").then((response) => {
        this.payment_status_list = response.data.data;
      });
    },

    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },

    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    showDetails(id) {
      this.$router.push({name: 'payment_purchase_invoices.details', params: {id: id}});
    },


    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },
  },
};
</script>
